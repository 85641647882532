import Trademarked from '../../../components/atoms/Trademarked';
import React from 'react';

export const pricings = [
  {
    title: 'Free',
    subtitle: (<>Free <Trademarked>Formigio</Trademarked> Account Required</>),
    price: 0,
    features: [
      'Secure Authentication',
      'Unlimited commitments',
      '3 Active Trackers'
    ],
    priceSuffix: ' / MO',
    isHighlighted: true,
    label: 'Try for Free',
    href: 'https://app.formigio.com/'
  },
  {
    title: 'Beginner',
    subtitle: 'Price shown in US Dollars',
    price: 5,
    priceSuffix: ' / MO',
    features: [
      'Secure Authentication',
      'Unlimited commitments',
      '12 Active Trackers'
    ],
    label: 'Start with Beginner',
    href: 'https://app.formigio.com/'
  },
  {
    title: 'Advanced',
    subtitle: 'Price shown in US Dollars',
    price: 20,
    priceSuffix: ' / MO',
    features: [
      'Secure Authentication',
      'Unlimited commitments',
      'Unlimited Trackers'
    ],
    label: 'Become Advanced',
    href: 'https://app.formigio.com/'
  },
];
