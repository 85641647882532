import React from 'react';
import { Main } from './components';

import { pricings } from './data';
import {Helmet} from 'react-helmet';

const Pricing = () => (
  <div>
    <Helmet>
      <title>Formigio (tm) - Sign up for free today. Try before you buy the app that can help you plan you day and track your goals</title>
    </Helmet>
  
    <Main data={pricings} />
  </div>
);

export default Pricing;
